import React, { useState, useEffect } from "react";

const NoteManager = ({ setSubmissionNotes }) => {
  const [text, setText] = useState("");
  const [customerName, setCustomerName] = useState("");

  useEffect(() => {
    const notes = {
      customerName: customerName.trim(),
      notes: text.split("\n").filter((line) => line.trim() !== ""),
    };
    setSubmissionNotes(notes);
  }, [text, customerName, setSubmissionNotes]);

  return (
    <div>
      <h5>Customer Name</h5>
      <input
        type="text"
        value={customerName}
        onChange={(e) => setCustomerName(e.target.value)}
        style={{
          display: "block",
          width: "50%",
          marginBottom: "20px",
          border: "1px solid #EBEDF3",
          outline: "none",
        }}
      />{" "}
      <h5>Submission Notes</h5>
      <textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        rows={5}
        cols={30}
        style={{
          display: "block",
          width: "100%",
          marginBottom: "20px",
          border: "1px solid #EBEDF3",
          outline: "none",
        }}
      />
    </div>
  );
};

export default NoteManager;
