import React from "react";
import { Modal } from "react-bootstrap";

const RateModal = ({
  showModal,
  handleShowModal,
  handleAddColumn,
  columnName,
  setColumnName,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={handleShowModal}
      className="modal fade"
      tabIndex={-1}
      id="kt_modal_1"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Enter Weight Only</h5>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></div>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              aria-describedby="basic-addon3 basic-addon4"
              value={columnName}
              onChange={(e) => setColumnName(e.target.value)}
            />
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            data-bs-dismiss="modal"
            onClick={handleShowModal}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => handleAddColumn(columnName)}
          >
            Save changes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RateModal;
