import axios from "axios";

export const LOGIN_URL = "https://primefreight.bettywebblocks.com/login";
export const FORGOT_PASSWORD_URL = process.env.NODE_ENV === "development" ? "https://primefreight-acceptance.bettywebblocks.com/portal_open_requests/forgot_password" : "https://primefreight.bettywebblocks.com/portal_open_requests/forgot_password";
export const CHECK_OTP_URL = process.env.NODE_ENV === "development" ? "https://primefreight-acceptance.bettywebblocks.com/portal_open_requests/check_otp" : "https://primefreight.bettywebblocks.com/portal_open_requests/check_otp";
export const RESET_PASSWORD_URL = process.env.NODE_ENV === "development" ? "https://primefreight-acceptance.bettywebblocks.com/portal_open_requests/reset-password" : "https://primefreight.bettywebblocks.com/portal_open_requests/reset-password";
export const SET_NEW_USER_PASSWORD_VERIFICATION = process.env.NODE_ENV === "development" ? "https://primefreight-acceptance.bettywebblocks.com/portal_open_requests/set-password-verification" : "https://primefreight.bettywebblocks.com/portal_open_requests/set-password-verification";
export const SET_NEW_USER_PASSWORD = process.env.NODE_ENV === "development" ? "https://primefreight-acceptance.bettywebblocks.com/portal_open_requests/set-password" : "https://primefreight.bettywebblocks.com/portal_open_requests/set-password";
export const UPDATE_RATE_SEARCH_VERIFICATION = process.env.NODE_ENV === "development" ? "https://primefreight-acceptance.bettywebblocks.com/portal_open_requests/rate-search-verification" : "https://primefreight.bettywebblocks.com/portal_open_requests/rate-search-verification";
export const API_URL = process.env.NODE_ENV === "development" ? `https://primefreight-portal-dev.herokuapp.com/proxy-request?url=https://primefreight.betty.app/api/runtime/da93364a26fb4eeb9e56351ecec79abb` : `https://primefreight.betty.app/api/runtime/da93364a26fb4eeb9e56351ecec79abb`;

export async function login(email, password) {
  return axios.post(LOGIN_URL, { email, password })
}

export function requestPassword(email) {
  return axios.post(FORGOT_PASSWORD_URL, { email }).then((response) => {
    let data = response.data;
    if (data && data.status === "200") {
      return true
    } else {
      return false;
    }
  }
  ).catch((error) => {
    return false;
  });
}

export function checkPasswordVerificationNewUser(email, invite_verification) {

  return axios.post(SET_NEW_USER_PASSWORD_VERIFICATION, { email, invite_verification }).then(result => {

    let data = result.data
    if (data && data.status === "200") {
      return true
    } else {
      return false
    }
  }).catch(err => {
    return false
  })

}

export function setPasswordNewUser(payload) {

  return axios.post(SET_NEW_USER_PASSWORD, payload).then(result => {

    let data = result.data
    if (data && data.status === "200") {
      return true
    } else {
      return false
    }
  }).catch(err => {
    return false
  })


}

export function checkOtpHTTP(email, otp) {

  return axios.post(CHECK_OTP_URL, { email, otp }).then(result => {
    let data = result.data
    if (data && data.status === "200") {
      return true
    } else {
      return false
    }
  }).catch(err => {
    return false
  }
  )

}

export function resetPasswordHTTP(payload) {
  return axios.post(RESET_PASSWORD_URL, payload, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${payload.authToken}`,
    }
  }).then(result => {
    let data = result.data
    if (data && data.status === "200") {
      return true
    } else {
      return false
    }
  }).catch(err => {
    return false
  }
  )
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.


  let query = `query Myinfo {
        meWebuser {
          id
          email,
          lastName,
          firstName,
          companyPosition,
          verification,
          rateSearchVerification,
          entity {
            id,
            verificationCode
          }
        }
      }`


  return axios.post(API_URL, { query }).then(result => {

    if (result.data && result.data.data && result.data.data.meWebuser) {
      let user = result.data.data.meWebuser

      return {
        id: user.id,
        email: user.email || "N/A",
        pic: "/media/users/default.jpg",
        fullname: user.firstName + user.lastName || "N/A",
        firstname: user.firstName || "N/A",
        lastname: user.lastName || "N/A",
        occupation: user.companyPosition || "N/A",
        language: "en",
        entity_id: user.entity.id,
        entity_verification_code: user.entity.verificationCode,
        verification: user.verification,
        rate_search_verification: user.rateSearchVerification

      }
    }

    return false

  })

}