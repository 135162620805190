import * as auth from "../app/modules/Auth/_redux/authRedux";

export default async function setupAxios(axios, store, props) {

    // console.log(store.getState());
    axios.interceptors.request.use(
        config => {
            const {
                auth: { authToken, expiresAt },
            } = store.getState();

            if (!expiresAt || !checkExpiration(expiresAt)|| !authToken) {
                store.dispatch(auth.actions.logout());
            }

            // const jwtToken1 = store.getState();
            if (authToken) {
                config.headers.Authorization = `Bearer ${authToken}`;
                config.headers['Content-Type'] = 'application/json';
            }
            return config;
        },
        err => {
            return Promise.reject(err);
        }

    );

}

function checkExpiration(expiresAt) {
    if (expiresAt) {
        const now = new Date();
        const expiration = new Date(expiresAt);
        return now < expiration;
    }
    return false;
}

