import React, { useState, useEffect } from "react";
import Select from "react-select";
import useInputHandler from "../Form/useInputHandler";

const RoutingInput = ({ index, records, airports, setRecords }) => {
  const [newAirports, setNewAirports] = useState([]);

  const { handleInputChange } = useInputHandler(records, setRecords);

  useEffect(() => {
    const updatedData = airports?.map(
      (item) => {
        return {
          label: item.code,
          value: item.code,
        };
      },
      [airports]
    );

    setNewAirports(updatedData);
  }, [airports]);

  const selectedRoutingValues =
    records[index]?.routing &&
    records[index]?.routing?.map((route) => ({
      label: route.value,
      value: route.value,
    }));

  return (
    <div style={{ padding: "26px 16px" }}>
      <div>
        <div style={{ minWidth: "261px", textAlign: "center" }}>
          <Select
            className="basic-multi-select"
            closeMenuOnSelect={true}
            options={newAirports}
            isMulti
            value={selectedRoutingValues}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            onChange={(newValue) => {
              const newRoutingValues = newValue
                ? newValue.map(({ value }, index) => ({
                    value: value.toUpperCase(),
                    index: index,
                  }))
                : [];
              handleInputChange(index, "routing", newRoutingValues);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RoutingInput;
