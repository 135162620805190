import React from "react";

const SelectControl = ({ options, onChange, value, placeholder }) => (
  <select
    className="form-control"
    value={value}
    onChange={onChange}
    style={{ minWidth: "90px" }}
  >
    <option value="">{placeholder}</option>
    {options?.map((option) => (
      <option key={option.key} value={option.value}>
        {option.text}
      </option>
    ))}
  </select>
);

export default SelectControl;
