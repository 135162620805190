/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import LocalShippingTwoToneIcon from '@material-ui/icons/LocalShippingTwoTone';
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { loadCSS } from 'fg-loadcss';
// import clsx from 'clsx';
import Icon from '@material-ui/core/Icon';
import { AccountCircleTwoTone, CheckBoxTwoTone, InsertDriveFileTwoTone, PeopleOutline, Description, AttachMoneyTwoTone, SearchTwoTone, LocationCityTwoTone, ScheduleTwoTone, ContactSupportTwoTone, LibraryBooksTwoTone } from "@material-ui/icons";
// import { makeStyles } from "@material-ui/core";
//import { shallowEqual, useSelector } from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu) => {
    return checkIsActive(location, url) &&
      `menu-item-active menu-item-open menu-item-not-hightlighted`

  };
  //const user = useSelector((state) => state.auth.user, shallowEqual);

  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
  }, []);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/shipments", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/shipments">
            <span className="svg-icon menu-icon">
              <Icon style={{ marginLeft: '-3px' }} className={'fa fa-fw fa-cube'} />
            </span>
            <span className="menu-text">Shipment</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/delivery", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/delivery">
            <span className="svg-icon menu-icon">
              <LocalShippingTwoToneIcon />
            </span>
            <span className="menu-text">Delivery</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/booking", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/booking">
            <span className="svg-icon menu-icon">
              <CheckBoxTwoTone />
            </span>
            <span className="menu-text">Bookings</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/purchase-order", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/purchase-order">
            <span className="svg-icon menu-icon">
              <InsertDriveFileTwoTone />
            </span>
            <span className="menu-text">Purchase Orders</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/supplier", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/supplier">
            <span className="svg-icon menu-icon">
              <PeopleOutline />
            </span>
            <span className="menu-text">Shipper</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
         {/*begin::1 Level*/}
         <li
          className={`menu-item ${getMenuItemActive("/agent", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/agent">
            <span className="svg-icon menu-icon">
              <PeopleOutline />
            </span>
            <span className="menu-text">Agents</span>
          </NavLink>
        </li>
         {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/manifest", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/manifest">
            <span className="svg-icon menu-icon">
              <Description />
            </span>
            <span className="menu-text">Manifest</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/quotes", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/quotes">
            <span className="svg-icon menu-icon">
              <AttachMoneyTwoTone />
            </span>
            <span className="menu-text">Quotes</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/schedule", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/schedule">
            <span className="svg-icon menu-icon">
              <ScheduleTwoTone />
            </span>
            <span className="menu-text">Schedules</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/rate-sheet", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/rate-sheet">
            <span className="svg-icon menu-icon">
              <AttachFileTwoTone />
            </span>
            <span className="menu-text">Rate Sheets</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}
        {/* begin::1 Level */}
        <li
          className={`menu-item ${getMenuItemActive("/rate-search", false)}`}
          aria-haspopup="true"
        >

          <NavLink className="menu-link" to="/rate-search">
            <span className="svg-icon menu-icon">
              <SearchTwoTone />
            </span>
            <span className="menu-text">Rate Search</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/* begin::1 Level */}
        <li
          className={`menu-item ${getMenuItemActive("/locations", false)}`}
          aria-haspopup="true"
        >

          <NavLink className="menu-link" to="/locations">
            <span className="svg-icon menu-icon">
              <LocationCityTwoTone />
            </span>
            <span className="menu-text">Locations</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/user-profile",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/user-profile">
            <span className="svg-icon menu-icon">
              <AccountCircleTwoTone />
            </span>
            <span className="menu-text">User </span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">

                </span>
              </li>

              {/* Layout */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive("/user-profile/personal-information", false)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                {/* <NavLink
                  className="menu-link menu-toggle"
                  to="/google-material/layout"
                >
                  <span className="menu-text">Layout</span>
                </NavLink> */}
                <NavLink className="menu-link menu-toggle" to="/user-profile/personal-information">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">User Profile</span>
                </NavLink>

              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive("/user-profile/account-information", false)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/user-profile/account-information">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Company Profile</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}


            </ul>
          </div>
        </li>
        {/*end::1 Level*/}
        {/* begin::1 Level */}
        <li
          className={`menu-item`}
          aria-haspopup="true"
        >

          {/* <NavLink className="menu-link" exact={true} to={{ pathname:`https://help.primefreight.com/?user=${encodeURIComponent(JSON.stringify(user))}` }} target={"_blank"}> */}
          <NavLink className="menu-link" exact={true} to={{ pathname: "https://help.primefreight.com/" }} target={"_blank"}>
            <span className="svg-icon menu-icon">
              <ContactSupportTwoTone />
            </span>
            <span className="menu-text">Help</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        <li
          className={`menu-item menu-item-submenu-1 ${getMenuItemActive(
            "/report",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/report">
            <span className="svg-icon menu-icon">
            <LibraryBooksTwoTone />
            </span>
            <span className="menu-text">Report </span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">

                </span>
              </li>

              {/* Layout */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu-1 ${getMenuItemActive("/report/ocean", false)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/report/ocean">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Ocean Report</span>
                </NavLink>

              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu-1 ${getMenuItemActive("/report/air", false)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/report/air">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Air Report</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}
        {/* begin::1 Level */}

      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
