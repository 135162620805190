import React, { useCallback } from "react";
import { TableRow, TableCell } from "@material-ui/core";
import SelectControl from "./SelectControl";
import NumericInputControl from "./NumericInputControl";
import RoutingInput from "../pages/RoutingInput";
import useInputHandler from "./useInputHandler";

const RateRow = ({
  rate,
  index,
  records,
  setRecords,
  airports,
  airlines,
  handleDuplicateRate,
}) => {
  const { handleInputChange, handleNumberValue } = useInputHandler(
    records,
    setRecords
  );

  const handleDeleteRecord = useCallback(
    (index) => {
      if (index > 0) {
        const updatedRecords = [...records];
        updatedRecords.splice(index, 1);
        setRecords(updatedRecords);
      }
    },
    [records]
  );
  return (
    <TableRow key={rate.id}>
      <TableCell>
        <SelectControl
          options={airports?.map((airport) => ({
            key: airport.code,
            value: airport.cityIden,
            text: `${airport.code} - ${airport.city}`,
          }))}
          value={rate.from}
          onChange={(e) => handleInputChange(index, "from", e.target.value)}
          placeholder="From"
        />
      </TableCell>
      <TableCell>
        <SelectControl
          options={airports?.map((airport) => ({
            key: airport.code,
            value: airport.cityIden,
            text: `${airport.code} - ${airport.city}`,
          }))}
          value={rate.to}
          onChange={(e) => handleInputChange(index, "to", e.target.value)}
          placeholder="To"
        />
      </TableCell>
      <TableCell>
        <SelectControl
          options={airlines?.map((airline) => ({
            key: airline.id,
            value: airline.companyName,
            text: airline.companyName,
          }))}
          value={rate.airline}
          onChange={(e) => handleInputChange(index, "airline", e.target.value)}
          placeholder="Airline"
        />
      </TableCell>
      {rate.weight.map((weight, weightIndex) => (
        <TableCell key={weightIndex}>
          <NumericInputControl
            value={weight.value}
            onChange={(newValue) =>
              handleNumberValue(index, weight.level, newValue, weightIndex)
            }
            width="90px"
            placeholder={weight.level}
          />
        </TableCell>
      ))}
      <TableCell>
        <div className="input-group min-w-150px">
          <NumericInputControl
            value={rate.minTime}
            onChange={(newValue) =>
              handleNumberValue(index, "minTime", newValue)
            }
            width="0px"
            placeholder="Min"
          />
          <span className="input-group-text rounded-0">-</span>
          <NumericInputControl
            value={rate.maxTime}
            onChange={(newValue) =>
              handleNumberValue(index, "maxTime", newValue)
            }
            width="0px"
            placeholder="Max"
          />
        </div>
      </TableCell>
      <TableCell>
        <NumericInputControl
          value={rate.maxCapacity}
          onChange={(newValue) =>
            handleNumberValue(index, "maxCapacity", newValue)
          }
          width="90px"
          placeholder="Capacity"
        />
      </TableCell>
      <TableCell>
        <RoutingInput
          index={index}
          records={records}
          airports={airports}
          setRecords={setRecords}
        />
      </TableCell>
      <TableCell>
        <input
          type="date"
          className="form-control"
          value={rate.validityFrom}
          onChange={(e) =>
            handleInputChange(index, "validityFrom", e.target.value)
          }
        />
      </TableCell>
      <TableCell>
        <input
          type="date"
          className="form-control"
          value={rate.validityTo}
          onChange={(e) =>
            handleInputChange(index, "validityTo", e.target.value)
          }
        />
      </TableCell>
      <TableCell>
        <textarea
          className="form-control"
          value={rate.comment}
          style={{ width: "200px" }}
          onChange={(e) => handleInputChange(index, "comment", e.target.value)}
          placeholder="Comment"
        />
      </TableCell>
      <TableCell style={{ textAlign: "center" }}>
        <button
          className="btn btn-secondary btn-sm"
          onClick={() => handleDuplicateRate(index, rate.id)}
        >
          <i style={{ paddingRight: 0 }} className="fa fa-solid fa-copy"></i>
        </button>
      </TableCell>
      <TableCell>
        <button
          type="button"
          className="btn btn-light-danger btn-icon btn-sm"
          onClick={() => handleDeleteRecord(index)}
        >
          <i className="fa fa-trash"></i>
        </button>
      </TableCell>
    </TableRow>
  );
};

export default RateRow;
