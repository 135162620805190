import React from "react";
import { TableBody } from "@material-ui/core";
import RateRow from "../Form/RateRow";
import useInputHandler from "../Form/useInputHandler";

const AddRateForm = ({
  records,
  airports,
  airlines,
  setRecords,
  handleDuplicateRate,
}) => {
  return (
    <TableBody>
      {records.map((rate, index) => (
        <RateRow
          key={index}
          rate={rate}
          index={index}
          records={records}
          setRecords={setRecords}
          airports={airports}
          airlines={airlines}
          handleDuplicateRate={handleDuplicateRate}
        />
      ))}
    </TableBody>
  );
};

export default AddRateForm;
