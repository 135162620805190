import React from "react";
import { Table } from "@material-ui/core";
import AddRateForm from "./AddRateForm";

const RatesTable = ({
  weightPrices,
  records,
  airports,
  airlines,
  setRecords,
  setMsg,
  submittedForm,
  handleDuplicateRate,
}) => {
  const asterisk = <span style={{ color: "#F64E60" }}>*</span>;
  return (
    <div
      className="table-responsive rounded"
      style={{ display: submittedForm ? "none" : "" }}
    >
      <Table className="table-bordered">
        <thead className="thead-dark">
          <tr className="text-center">
            <th>From{asterisk}</th>
            <th>To{asterisk}</th>
            <th>Airline{asterisk}</th>
            {weightPrices.map((weight, index) => (
              <th key={index}>
                {!isNaN(parseInt(weight.level))
                  ? `+ ${weight.level} Kgs`
                  : weight.level}
              </th>
            ))}
            <th>T/Time{asterisk}</th>
            <th>Max Capacity</th>
            <th>Routing{asterisk}</th>
            <th>Validity From{asterisk}</th>
            <th>Validity To{asterisk}</th>
            <th>Rate Specific Notes</th>
            <th style={{ padding: "0 10px" }}>Duplicate</th>
            <th></th>
          </tr>
        </thead>
        <AddRateForm
          setMsg={setMsg}
          records={records}
          airports={airports}
          airlines={airlines}
          setRecords={setRecords}
          handleDuplicateRate={handleDuplicateRate}
        />
      </Table>
    </div>
  );
};

export default RatesTable;
