import CreatableSelect from "react-select/creatable";
import React, { useState, useEffect, useCallback } from "react";
import {
  getBookingDetail,
  getShipmentDetail,
  updatePurchaseOrder,
  addPurchaseOrder,
  updateStyleNumber,
  addStyleNumber,
  searchPOs,
  searchSNs,
  quickAddPOs,
  quickAddSNs,
} from "./api.service";
import debounce from "lodash/debounce";
import Button from "@material-ui/core/Button";
import { useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const customStyles = {
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "rgba(0, 82, 204, 0.1)",
    color: "rgb(0, 82, 204)",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "rgb(0, 82, 204)",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "rgb(0, 82, 204)",
    "&:hover": {
      backgroundColor: "#0052CC",
      color: "white",
    },
  }),
};

function PoAndStyleManager() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = parseInt(searchParams.get("record_id"));

  const PAGE_TYPE = {
    BOOKING: "booking",
    SHIPMENT: "shipment",
  };
  const [poInputValue, setPoInputValue] = useState("");
  const [snInputValue, setSnInputValue] = useState({});

  const [state, setState] = useState({
    bookingData: null,
    shipmentData: null,
    type: null,
    loading: true,
    fetchPOLoading: false,
    fetchSNLoading: false,
    selectedPos: [],
    alertVisible: false,
    alertMessage: "",
    alertSeverity: "",
    loadingSns: false,
    processSN: false,
    poOptions: [],
    snOptions: [],
  });

  // Fetch data initially
  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      try {
        // Get Data from Betty as per the URL
        if (location.pathname.includes("/booking/")) {
          const result = await getBookingDetail(id);

          if (isSubscribed) {
            if (!result[0]) {
              setState((prevState) => ({
                ...prevState,
                loading: false,
                alertMessage: `Error fetching booking data: ${result[1]}. Please try again later, if issue stays, contact Prime Freight`,
                alertSeverity: "warning",
                alertVisible: true,
              }));
            } else {
              const bookingData = result[1];
              setState((prevState) => ({
                ...prevState,
                loading: false,
                type: PAGE_TYPE.BOOKING,
                bookingData: bookingData,
              }));
            }
          }
        } else if (location.pathname.includes("/shipments/")) {
          const result = await getShipmentDetail(id);

          if (isSubscribed) {
            if (!result[0]) {
              setState((prevState) => ({
                ...prevState,
                loading: false,
                alertMessage: `Error fetching shipment data: ${result[1]}. Please try again later, if issue stays, contact Prime Freight`,
                alertSeverity: "warning",
                alertVisible: true,
              }));
            } else {
              const shipmentData = result[1];
              setState((prevState) => ({
                ...prevState,
                loading: false,
                type: PAGE_TYPE.SHIPMENT,
                shipmentData: shipmentData,
              }));
            }
          }
        }
      } catch (error) {
        if (isSubscribed) {
          setState((prevState) => ({
            ...prevState,
            bookingData: {},
          }));
        }
        setState((prevState) => ({
          ...prevState,
          loading: false,
          alertMessage: `Error loading page: ${error ||
            error.message}. Please try again later. If issue stays, contact Prime Freight`,
          alertSeverity: "warning",
          alertVisible: true,
        }));
        console.error("Error fetching data :", error);
      }
    };

    if (isSubscribed && !isNaN(id)) {
      fetchData();
    }

    return () => {
      isSubscribed = false;
    };
  }, [id]);

  // Update selectedPos based on object data
  useEffect(() => {
    let selectedPos = [];

    if (state.bookingData) {
      // Derive selectedPos from bookingData
      selectedPos =
        state.bookingData?.pos?.map((order) => ({
          value: order.id,
          label: order.orderNumbers,
          styleNumbers: order.styleNumbers.map((SN) => ({
            id: SN.id,
            styleNumber: SN.styleNumber,
          })),
        })) ?? [];
    } else if (state.shipmentData) {
      selectedPos =
        state.shipmentData?.purchaseOrders?.map((order) => ({
          value: order.id,
          label: order.orderNumbers,
          styleNumbers: order.styleNumbers.map((SN) => ({
            id: SN.id,
            styleNumber: SN.styleNumber,
          })),
        })) ?? [];
    }

    setState((prevState) => ({
      ...prevState,
      selectedPos: selectedPos,
    }));
  }, [state.bookingData, state.shipmentData]);

  // Handle changes to style numbers within a purchase order
  // Used to handle adding existing purchase orders
  const handleChangeSN = (inputValue, poId) => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    const updatedPoData = state.selectedPos.map((po) => {
      if (po.value === poId) {
        return {
          ...po,
          styleNumbers: inputValue
            ? inputValue.map((sn) => ({
                id: sn.value,
                styleNumber: sn.label,
              }))
            : [],
        };
      }
      return po;
    });
    setState((prevState) => ({
      ...prevState,
      loading: false,
      selectedPos: updatedPoData,
    }));
  };

  // Handle addition of a new style number
  const handleNewSN = async (inputValue, poId) => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    const payload = {
      styleNumber: inputValue,
      poId: poId,
      type: state.type,
      id:
        state.type === PAGE_TYPE.BOOKING
          ? state.bookingData?.id
          : state.shipmentData?.id,
    };

    try {
      // Send Betty information to create new style nuymber
      const result = await addStyleNumber(payload);
      if (!result[0]) {
        setState((prevState) => ({
          ...prevState,
          alertMessage: `Unable to add style number ${result[1]}. Please try again later. If issue stays, contact Prime Freight`,
          alertSeverity: "warning",
          loading: false,
          alertVisible: true,
        }));
      } else {
        // Create local style number
        const newSN = { id: result[1], styleNumber: inputValue };

        const updatedSelectedPoData = state.selectedPos.map((po) => {
          if (po.value === poId) {
            return {
              ...po,
              styleNumbers: [...po.styleNumbers, newSN],
            };
          }
          return po;
        });
        setState((prevState) => ({
          ...prevState,
          loading: false,
          selectedPos: updatedSelectedPoData,
        }));
        // returning newSN to be added in case quick adding style numbers
        return newSN;
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        alertMessage: `Error occured while adding SN: ${error ||
          error.message}. Please try again later, if issue stays contact Prime Freight`,
        alertSeverity: "warning",
        alertVisible: true,
      }));
      console.error("Error occurred while adding SN :", error);
    }
  };

  // Handle changes to purchase orders
  // Handles Existing Purchase Orders
  const handleChangePO = async (inputValue) => {
    setState((prevState) => ({
      ...prevState,
      loadingSns: true,
      loading: true,
      selectedPos: inputValue || [],
    }));
    const payload = {
      selectedPOs: inputValue ? inputValue.map((po) => ({ id: po.value })) : [],
      id:
        state.type === PAGE_TYPE.BOOKING
          ? state.bookingData?.id
          : state.shipmentData?.id,
      type: state.type,
    };

    try {
      // updating Betty with purchase order object
      const result = await updatePurchaseOrder(payload);
      if (!result[0]) {
        setState((prevState) => ({
          ...prevState,
          alertMessage: `Unable to add purchase order: ${result[1]}. Please try again later, if issue stays contact Prime Freight.`,
          alertSeverity: "warning",
          loading: false,
          alertVisible: true,
          loadingSns: false,
        }));
      } else {
        const newPOs = inputValue
          ? inputValue.filter(
              (po) =>
                !(state.selectedPos || []).some(
                  (selectedPO) => selectedPO.value === po.value
                )
            )
          : [];
        // updated PO object to handle loading style numbers
        const updatedNewPOs = newPOs.map((newPO) => {
          let styleNumbers = newPO.styleNumbers;

          if (!styleNumbers || styleNumbers.length === 0) {
            const poData = state.poOptions.find(
              (po) => po.value === newPO.value
            );
            if (poData) {
              styleNumbers = poData.styleNumbers.map((SN) => ({
                id: SN.id,
                styleNumber: SN.styleNumber,
              }));
            } else {
              styleNumbers = [];
            }
          }

          return {
            ...newPO,
            styleNumbers,
          };
        });

        const updatedSelectedPOs = state.selectedPos
          ? state.selectedPos.filter((selectedPO) =>
              (inputValue || []).some((po) => po.value === selectedPO.value)
            )
          : [];

        const combinedSelectedPOs = [...updatedSelectedPOs, ...updatedNewPOs];

        setState((prevState) => ({
          ...prevState,
          loading: false,
          selectedPos: combinedSelectedPOs,
          loadingSns: false,
        }));
      }
    } catch (error) {
      console.error("Error updating PO :", error);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        alertMessage: `Error updating PO: ${error ||
          error.message}. Please try again later, if issue stays contact Prime Freight`,
        alertSeverity: "warning",
        alertVisible: true,
      }));
      setState((prevState) => ({
        ...prevState,
        loading: false,
        loadingSns: false,
      }));
    }
  };
  // used to dynamically fetch the options from the search boxes
  const fetchOptions = useCallback(
    debounce(async (inputValue, type) => {
      if (inputValue && type === "purchaseOrders") {
        setState((prevState) => ({
          ...prevState,
          fetchPOLoading: true,
        }));
        let options = [];
        // looks up all PO's that contain the input string
        if (state.type === PAGE_TYPE.BOOKING) {
          options = await searchPOs(
            inputValue,
            state.bookingData?.customer?.id
          );
        } else if (state.type === PAGE_TYPE.SHIPMENT) {
          options = await searchPOs(
            inputValue,
            state.shipmentData?.companyRelation?.id
          );
        }
        if (!options[0]) {
          setState((prevState) => ({
            ...prevState,
            fetchPOLoading: false,
            alertMessage: `Error fetching PO options: ${options[1]}. Please try again later, if issue stays, contact Prime Freight.`,
            alertVisible: true,
            alertSeverity: "warning",
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            fetchPOLoading: false,
            poOptions: options[1].map((PO) => ({
              value: PO.id,
              label: PO.orderNumbers,
              styleNumbers: (PO.styleNumbers || []).map((SN) => ({
                id: SN.id,
                styleNumber: SN.styleNumber,
              })),
            })),
          }));
        }
      } else if (inputValue && type === "styleNumbers") {
        let options = [];
        setState((prevState) => ({
          ...prevState,
          fetchSNLoading: true,
        }));
        if (state.type === PAGE_TYPE.BOOKING) {
          options = await searchSNs(
            inputValue,
            state.bookingData?.customer?.id
          );
        } else if (state.type === PAGE_TYPE.SHIPMENT) {
          options = await searchSNs(
            inputValue,
            state.shipmentData?.companyRelation?.id
          );
        }

        if (!options[0]) {
          setState((prevState) => ({
            ...prevState,
            fetchSNLoading: false,
            alertSeverity: "warning",
            alertVisible: true,
            alertMessage: `Error fetching SN options: ${options[1]}. Please try again later, if issue stays, contact Prime Freight`,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            fetchSNLoading: false,
            snOptions: options[1].map((item) => ({
              value: item.id,
              label: item.styleNumber,
            })),
          }));
        }
      }
    }, 300)
  );
  // Function solely for handling quick add
  const handlePOInputChange = (inputValue, { action }) => {
    setState((prevState) => ({
      ...prevState,
      alertVisible: false,
    }));
    setPoInputValue(inputValue);
    // if the input contains these characters, need to handle quick add
    if (
      inputValue.includes(",") ||
      inputValue.includes(" ") ||
      inputValue.includes("+") ||
      inputValue.includes("&")
    ) {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      // split the string along comma, space, +, &, trim each order number, filter boolean to get rid of null values
      const values = inputValue
        .split(/[\s,+&]+/)
        .map((value) => value.trim())
        .filter(Boolean);

      // split by existing / new purchase orders
      const existingPOs = [];
      const newPOs = [];
      // recursive function to handle the process of POs at the end
      const processPO = (index) => {
        if (index >= values.length) {
          setPoInputValue("");
          let combinedPOs = [...existingPOs];

          if (existingPOs.length > 0) {
            // filter out any purchase orders that already exist
            if (state.selectedPos.length > 0) {
              combinedPOs = [
                ...combinedPOs,
                ...state.selectedPos.filter(
                  (selectedPO) =>
                    !combinedPOs.some(
                      (existingPO) => existingPO === selectedPO.value
                    )
                ),
              ];
            }
            // Deduplicate by 'label' or 'orderNumbers'
            combinedPOs = combinedPOs.reduce((acc, current) => {
              const x = acc.find((item) => item.label === current.label);
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, []);
            // handle existing POs, and then handle New POs (for state management)
            handleChangePO(combinedPOs).then(() => {
              if (newPOs.length > 0) {
                const dedupedNewPOs = Array.from(
                  new Map(newPOs.map((po) => [po.value, po])).values()
                );
                dedupedNewPOs.forEach((newPO) => handleNewPO(newPO.value));
              }
            });
          } else if (newPOs.length > 0) {
            // Deduplicate by value
            const dedupedMap = new Map();
            newPOs.forEach((po) => dedupedMap.set(po.value, po));
            const dedupedNewPOs = Array.from(dedupedMap.values());
            dedupedNewPOs.forEach((newPO) => handleNewPO(newPO.value));
          }
          return;
        }

        const value = values[index];

        let promise;
        // seeing if the purchase order exists
        if (state.type === PAGE_TYPE.BOOKING) {
          promise = quickAddPOs(value, state.bookingData?.customer?.id);
        } else if (state.type === PAGE_TYPE.SHIPMENT) {
          promise = quickAddPOs(value, state.shipmentData?.companyRelation?.id);
        } else {
          return;
        }

        promise
          .then((result) => {
            if (!result[0]) {
              setState((prevState) => ({
                ...prevState,
                loading: false,
                alertMessage: `Failed to add purchase order: ${result[1]}. Please try again later, if issue stays, contact Prime Freight.`,
                alertSeverity: "warning",
                alertVisible: true,
              }));
              return;
            }
            // purchase order already exists
            if (result[1] && result[1].length > 0) {
              const existingPO = result[1][0];
              // pushing to array so that all the purchase orders can be handled together
              existingPOs.push({
                value: existingPO.id,
                label: existingPO.orderNumbers,
                styleNumbers: existingPO.styleNumbers,
              });
            } else {
              // similar logic so all the purchase orders can be handled to betty correctly
              newPOs.push({ value });
            }
            processPO(index + 1);
          })
          .catch((error) => {
            setState((prevState) => ({
              ...prevState,
              loading: false,
              alertMessage: `Unable to quick add purchase orders: ${error?.message ||
                error}`,
              alertSeverity: "warning",
              alertVisible: true,
            }));
            console.error("Error quick adding: ", error);
          });
      };
      processPO(0);
    } else {
      // fetches options if there is no escape characters in the input
      fetchOptions(inputValue, "purchaseOrders");
    }
  };

  // similar logic to that of the purchase orders
  const handleSNInputChange = (inputValue, poId) => {
    setState((prevState) => ({
      ...prevState,
      alertVisible: false,
    }));

    setSnInputValue((prevValues) => ({
      ...prevValues,
      [poId]: inputValue,
    }));
    if (
      inputValue.includes(",") ||
      inputValue.includes(" ") ||
      inputValue.includes("+") ||
      inputValue.includes("&")
    ) {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const values = inputValue
        .split(/[\s,+&]+/)
        .map((value) => value.trim())
        .filter(Boolean);

      let existingSNs = [];
      let newSNs = [];

      // process & handling of existing SN
      const processExistingSN = (newSNResults) => {
        if (
          existingSNs.length == 0 &&
          newSNResults &&
          newSNResults.length > 0
        ) {
          const updatedPoData = state.selectedPos.map((PO) => {
            if (PO.value === poId) {
              const updatedStyleNumbers = [
                ...PO.styleNumbers,
                ...newSNResults.filter(
                  (addedSN) =>
                    !PO.styleNumbers.some((SN) => SN.id === addedSN.id)
                ),
              ];
              return {
                ...PO,
                styleNumbers: updatedStyleNumbers,
              };
            }
            return PO;
          });
          setState((prevState) => ({
            ...prevState,
            selectedPos: updatedPoData,
          }));
        }
        if (existingSNs.length > 0) {
          existingSNs = Array.from(
            new Map(existingSNs.map((SN) => [SN.id, SN])).values()
          );
          if (newSNResults && newSNResults.length > 0) {
            existingSNs = [...existingSNs, ...newSNResults.flat()];
          }
          const updatedPoData = state.selectedPos.map((PO) => {
            if (PO.value === poId) {
              const updatedStyleNumbers = [
                ...PO.styleNumbers,
                ...existingSNs.filter(
                  (addedSN) =>
                    !PO.styleNumbers.some((SN) => SN.id === addedSN.id)
                ),
              ];
              return {
                ...PO,
                styleNumbers: updatedStyleNumbers,
              };
            }
            return PO;
          });
          setState((prevState) => ({
            ...prevState,
            selectedPos: updatedPoData,
            processSN: false,
            loading: false,
          }));
        }
      };

      const processSN = (index) => {
        if (index >= values.length) {
          setSnInputValue("");
          if (newSNs.length > 0) {
            const uniqueNewSNs = Array.from(new Set(newSNs));
            const newSNPromises = uniqueNewSNs.map((newSN) =>
              handleNewSN(newSN, poId)
            );

            Promise.all(newSNPromises).then((results) => {
              processExistingSN(results);
            });
          } else {
            processExistingSN();
          }
          return;
        }

        const value = values[index];

        let promise;
        if (state.type === PAGE_TYPE.BOOKING) {
          promise = quickAddSNs(value, state.bookingData?.customer?.id);
        } else if (state.type === PAGE_TYPE.SHIPMENT) {
          promise = quickAddSNs(value, state.shipmentData?.companyRelation?.id);
        } else {
          return;
        }

        promise
          .then((result) => {
            if (!result[0]) {
              setState((prevState) => ({
                ...prevState,
                alertMessage: `Failed to add style number: ${result[1]}. Please try again later, if issue stays, contact Prime Freight.`,
                alertSeverity: "warning",
                alertVisible: true,
                loading: false,
              }));
            } else {
              // SN exists
              if (result[1] && result[1].length !== 0) {
                const existingSN = result[1][0];
                existingSNs = [
                  ...existingSNs,
                  { id: existingSN.id, styleNumber: existingSN.styleNumber },
                ];
              } else {
                newSNs = [...newSNs, value];
              }
              processSN(index + 1);
            }
          })
          .catch((error) => {
            setState((prevState) => ({
              ...prevState,
              loading: false,
              alertMessage: `Unable to quick add style numbers: ${error?.message ||
                error}`,
              alertSeverity: "warning",
              alertVisible: true,
            }));
            console.error("Error quick adding style numbers : ", error);
          });
      };
      processSN(0);
    } else {
      fetchOptions(inputValue, "styleNumbers");
    }
  };
  // Handle new purchase orders that do not exist
  const handleNewPO = async (inputValue) => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    const payload = {
      type: state.type,
      id:
        state.type === PAGE_TYPE.BOOKING
          ? state.bookingData?.id
          : state.shipmentData?.id,
      orderNumber: inputValue,
    };

    try {
      // Create a new purchase order with betty
      const result = await addPurchaseOrder(payload);
      if (result[0]) {
        const newPO = {
          id: result[1],
          orderNumbers: inputValue,
          styleNumbers: [],
        };

        setState((prevState) => ({
          ...prevState,
          loading: false,
          selectedPos: [
            ...prevState.selectedPos,
            { value: newPO.id, label: newPO.orderNumbers, styleNumbers: [] },
          ],
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          alertMessage: `Unable to add purchase order: ${result[1]}. Please try again later, if issue stays, please contact Contact Prime Freight.`,
          alertSeverity: "warning",
          alertVisible: true,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        alertMessage: `Error occured while adding PO: ${error ||
          error.message}. Please try again later, if issue stays, contact Prime Freight`,
        alertSeverity: "warning",
        alertVisible: true,
      }));
      console.error("Error occurred while adding PO :", error);
    }
  };

  // Handle save changes
  const handleSaveChanges = async () => {
    setState((prevState) => ({
      ...prevState,
      alertVisible: false,
    }));
    await handleChangePO(state.selectedPos);
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    // Runs handleChangePO and handleChangeSN
    if (state.selectedPos !== null) {
      try {
        const payload = {
          type: state.type,
          id:
            state.type === PAGE_TYPE.BOOKING
              ? state.bookingData?.id
              : state.shipmentData?.id,
          purchaseOrder: state.selectedPos.map((selectedPO) => ({
            id: selectedPO.value,
            selectedSN: selectedPO.styleNumbers.map((SN) => ({
              id: SN.id,
            })),
          })),
        };
        await updateStyleNumber(payload);

        setState((prevState) => ({
          ...prevState,
          loading: false,
          alertMessage: "Changes Saved. You may now close this page.",
          alertSeverity: "success",
          alertVisible: true,
        }));
      } catch (error) {
        console.error("Error Saving Changes:", error);
        setState((prevState) => ({
          ...prevState,
          loading: false,
          alertMessage: `Error Saving Changes: ${error ||
            error.message}. Please try again later, if issue stays, contact Prime Freight`,
          alertSeverity: "warning",
          alertVisible: true,
        }));
      }
    }
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "20px",
        marginTop: "20px",
        marginLeft: "20px",
        marginRight: "20px",
        height: "100vh",
        overflowY: "scroll",
      }}
    >
      <Backdrop
        open={state.loading || state.loadingSns}
        style={{
          zIndex: 9999,
          color: "#000",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(0,0,0,0.4 )",
            width: "100%",
            height: "100%",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      </Backdrop>
      <h2>
        <b>Purchase Order and Style Number Manager</b>
      </h2>
      <span>
        <b>Purchase Orders:</b>
      </span>
      <br></br>
      <p>
        Search to add new / existing POs you have with this shipper by clicking
        the blank space next to the POs, or remove POs from this booking by
        clicking the X next to the item.
      </p>
      <h3>
        <b>Please remember to save changes</b>
      </h3>
      {/*  search bar for purchase orders*/}
      <CreatableSelect
        isMulti
        isDisabled={state.loading}
        isLoading={state.fetchPOLoading}
        options={state.poOptions}
        placeholder="Select or create purchase orders"
        formatCreateLabel={(inputValue) => (
          <div style={{ fontWeight: "bold" }}>Add new PO "{inputValue}"</div>
        )}
        noOptionsMessage={() => (
          <div style={{ fontWeight: "bold", color: "black" }}>
            Type to search for options
          </div>
        )}
        onCreateOption={handleNewPO}
        inputValue={poInputValue}
        onInputChange={handlePOInputChange}
        onChange={(newValue) => handleChangePO(newValue)}
        value={state.selectedPos}
        styles={customStyles}
      />
      <hr></hr>
      {state.selectedPos ? (
        <div>
          <p>
            <b>
              Search to add new / existing style numbers to the respective
              purchase order
            </b>
          </p>
          <p>
            Click the blank space next to the SNs, or remove SNs from a purchase
            order by clicking the X next to the item.{" "}
          </p>
          <Grid container spacing={8} key={1}>
            <Grid container item xs={4} alignItems="center">
              <h3>
                <b>Purchase Orders:</b>
              </h3>
            </Grid>
            <Grid container item xs={8} alignItems="center">
              <h3>
                <b>Style Numbers:</b>
              </h3>
            </Grid>
          </Grid>
        </div>
      ) : null}
      {state.selectedPos && !state.loadingSns ? (
        state.selectedPos.map((po) => {
          const filteredSNs = (po.styleNumbers || []).map((sn) => ({
            value: sn.id,
            label: sn.styleNumber,
          }));
          return (
            <Grid container spacing={8} key={po.value}>
              <Grid container item xs={4} alignItems="center">
                <h3 style={{ justifyContent: "center", alignItems: "center" }}>
                  <b>Purchase Order "{po.label}" :</b>
                </h3>
              </Grid>
              <Grid container item xs={8} alignItems="center">
                <div style={{ width: "100%" }}>
                  <CreatableSelect
                    isMulti
                    isDisabled={state.loading}
                    isLoading={state.fetchSNLoading}
                    options={state.snOptions}
                    placeholder="Select or create style numbers"
                    formatCreateLabel={(inputValue) => (
                      <div style={{ fontWeight: "bold" }}>
                        Add new SN "{inputValue}"
                      </div>
                    )}
                    onCreateOption={(inputValue) =>
                      handleNewSN(inputValue, po.value)
                    }
                    noOptionsMessage={() => (
                      <div style={{ fontWeight: "bold", color: "black" }}>
                        Type to search for options
                      </div>
                    )}
                    onInputChange={(inputValue) =>
                      handleSNInputChange(inputValue, po.value)
                    }
                    inputValue={snInputValue[po.value] || ""}
                    onChange={(newValue) => handleChangeSN(newValue, po.value)}
                    value={filteredSNs}
                    styles={customStyles}
                  />
                </div>
              </Grid>
            </Grid>
          );
        })
      ) : state.loadingSns ? (
        <></>
      ) : null}
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            style={{ backgroundColor: "#0052CC", color: "white" }}
            variant="contained"
            onClick={handleSaveChanges}
            size="large"
          >
            Save Changes
          </Button>
        </div>
        {state.alertVisible ? (
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <Alert
              severity={state.alertSeverity}
              variant="filled"
              onClose={() =>
                setState((prevState) => ({
                  ...prevState,
                  alertVisible: false,
                }))
              }
            >
              {state.alertMessage}
            </Alert>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default PoAndStyleManager;
