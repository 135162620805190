import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const IframePage = () => {
  const query = useQuery();
  const initialUrl = query.get("url");

  const storedUrl = sessionStorage.getItem("iframeUrl");
  const [url, setUrl] = useState(storedUrl || initialUrl);

  useEffect(() => {
    if (!storedUrl && initialUrl) {
      sessionStorage.setItem("iframeUrl", initialUrl);
      setUrl(initialUrl);
    }
    return () => {
      console.log("IframePage component unmounted");
    };
  }, [initialUrl, storedUrl, url]);

  if (!url) {
    return (
      <p>
        No URL provided. Please provide a URL parameter like
        ?url=http://example.com.
      </p>
    );
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe
        src={url}
        title="Iframe Viewer"
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default IframePage;
