import React from "react";
import { Button } from "react-bootstrap";

const CustomButton = ({ name, icon, func, display }) => {
  return (
    <Button
      variant="outline-info"
      className="col-md-2"
      style={{
        width: "100%",
        maxWidth: "200px",
        marginTop: "1.5em",
        marginBottom: "1.5em",
        marginRight: "1em",
        display: display ? "none" : "",
      }}
      onClick={func}
    >
      <i
        className={icon}
        style={{ fontSize: "small", verticalAlign: "initial" }}
      ></i>
      &nbsp;{name}
    </Button>
  );
};

export default CustomButton;
