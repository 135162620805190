import React from "react";
import { FormControl } from "react-bootstrap";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { getChargeTypes } from "../services/api.service";
import useFetchWithRetry from "../services/useFetchWithRetry";

const AddCharge = ({ charges, setCharges }) => {
  const { data: chargesData } = useFetchWithRetry(getChargeTypes);

  const handleInputChange = (index, fieldName, value) => {
    if (!chargesData || typeof chargesData !== "object") {
      console.error("Invalid chargesData provided:", chargesData);
    }

    const updatedCharges = [...charges];
    const numericValue = value.replace(/[^0-9.,]/g, "");

    const matchingCharge = chargesData.find(
      (chargeData) => chargeData.description === updatedCharges[index].name
    );

    updatedCharges[index] = {
      ...updatedCharges[index],
      id: matchingCharge ? matchingCharge.id : updatedCharges[index].id,
      [fieldName]:
        fieldName === "kgRate" ||
        fieldName === "shipmentRate" ||
        fieldName === "minRate"
          ? numericValue
          : value,
    };
    setCharges(updatedCharges);
  };

  const handleDeleteRecord = (index) => {
    if (index > 0) {
      const updatedCharges = [...charges];
      updatedCharges.splice(index, 1);
      setCharges(updatedCharges);
    }
  };

  return (
    <div
      style={{
        width: "850px",
      }}
    >
      <Table className="table-bordered">
        <thead>
          <TableRow className="text-center">
            <th>Charge</th>
            <th>Currency</th>
            <th>Rate /kg</th>
            <th>Rate /shipment</th>
            <th>Min</th>
          </TableRow>
        </thead>
        <TableBody>
          {charges?.map((charge, index) => (
            <TableRow key={index}>
              <td style={{ width: "200px", paddingRight: "20px" }}>
                <select
                  type="select"
                  className="form-control"
                  style={{ margin: "10px" }}
                  value={charge.name || ""}
                  onChange={(e) =>
                    handleInputChange(index, "name", e.target.value)
                  }
                >
                  <option value="">name</option>
                  {chargesData &&
                    chargesData?.map((charge) => (
                      <option key={charge.id} value={charge.description}>
                        {charge.description}
                      </option>
                    ))}
                </select>
              </td>
              <td
                style={{ width: "110px", padding: "10px", textAlign: "center" }}
              >
                <select
                  type="select"
                  className="form-control"
                  onChange={(e) =>
                    handleInputChange(index, "currency", e.target.value)
                  }
                >
                  <option value="USD">USD</option>
                  <option value="CAD">CAD</option>
                </select>
              </td>
              <td>
                <FormControl
                  type="text"
                  placeholder="value"
                  value={charge.kgRate}
                  onChange={(e) =>
                    handleInputChange(index, "kgRate", e.target.value)
                  }
                  style={{
                    margin: "10px",
                    width: "150px",
                    textAlign: "center",
                  }}
                />
              </td>
              <td>
                <FormControl
                  type="text"
                  placeholder="value"
                  value={charge.shipmentRate}
                  onChange={(e) =>
                    handleInputChange(index, "shipmentRate", e.target.value)
                  }
                  style={{
                    margin: "10px",
                    width: "150px",
                    textAlign: "center",
                  }}
                />
              </td>
              <td style={{ padding: "10px" }}>
                <FormControl
                  type="text"
                  placeholder="min"
                  value={charge.minRate}
                  onChange={(e) =>
                    handleInputChange(index, "minRate", e.target.value)
                  }
                  style={{
                    margin: "10px",
                    width: "150px",
                    textAlign: "center",
                  }}
                />
              </td>
              <TableCell
                style={{ padding: "26px 16px" }}
                className="align-middle"
              >
                <button
                  type="button"
                  onClick={() => handleDeleteRecord(index)}
                  className="btn btn-light-danger btn-icon btn-xs"
                >
                  <i className="fa fa-trash"></i>
                </button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default AddCharge;
