import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { checkPasswordVerificationNewUser, setPasswordNewUser } from "../_redux/authCrud";

const initialValues = {
  password: null,
  confirmpassword: null,
};


function SetPassword(props) {
  let { intl } = props;

  const [data, setData] = useState({
    spinner: true,
    verificationStatus: false,
    email: null,
    invite_verification: null,
    isSuccess: false,
    isRequested: false
  });

  useEffect(() => {

    fetchVerification()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchVerification = async () => {
    const params = new URLSearchParams(props.location.search);
    const email = params.get("email");
    const invite_verification = params.get("invite_verification");

    if (email && invite_verification) {
      //set email and invite_verification in state
      let verificationResult = await checkPasswordVerificationNewUser(email, invite_verification)

      // console.log(verificationResult);
      if (!verificationResult) {

        setData({
          ...data,
          spinner: false,
        })

        setTimeout(() => {

          props.history.push("/login");

        }, 5000);

      } else {
        setData({ ...data, email: email, spinner: false, invite_verification: invite_verification, verificationStatus: true });
        // User Verified
      }
    } else {
      //redirect to login page
      props.history.push("/login");

    }
  }

  const ResetpasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(0, "Minimum 0 character")
      .max(50, "Maximum 50 character")
      .required(
        intl.formatMessage(
          { id: "AUTH.VALIDATION.REQUIRED_FIELD" },
        )
      ).nullable(),
    confirmpassword: Yup.string()
      .min(0, "Minimum 0 character")
      .max(50, "Maximum 50 character")
      .required(
        intl.formatMessage(
          { id: "AUTH.VALIDATION.REQUIRED_FIELD" },
        )
      ).nullable(),
    first_name: Yup.string()
      .min(0, "Minimum 0 character")
      .max(50, "Maximum 50 character")
      .required(
        intl.formatMessage(
          { id: "AUTH.VALIDATION.REQUIRED_FIELD" },
        )
      ).nullable(),
    last_name: Yup.string()
      .min(0, "Minimum 0 character")
      .max(50, "Maximum 50 character")
      .required(
        intl.formatMessage(
          { id: "AUTH.VALIDATION.REQUIRED_FIELD" },
        )
      ).nullable(),
  });


  const getInputClasses = (fieldname) => {
    if (resetPassword.touched[fieldname] && resetPassword.errors[fieldname]) {
      return "is-invalid";
    }

    if (resetPassword.touched[fieldname] && !resetPassword.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const resetPassword = useFormik({
    initialValues,
    validationSchema: ResetpasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // console.log(values);
      if (values.password !== values.confirmpassword) {
        setSubmitting(false);
        setStatus(
          intl.formatMessage(
            { id: "AUTH.VALIDATION.INVALID" },
            { name: 'Password and Confirm Password must be same' }
          )
        );
        return;
      }
      if (!values.password || !values.confirmpassword || !data.email || !data.invite_verification || !values.first_name || !values.last_name) {

        setSubmitting(false);

        setStatus(
          intl.formatMessage(
            { id: "AUTH.VALIDATION.INVALID" },
            { name: 'Please Try After Sometime' }
          )
        );
        return;
      }

      let payload = {
        email: data.email,
        password: values.password,
        invite_verification: data.invite_verification,
        first_name: values.first_name,
        last_name: values.last_name
      }

      setPasswordNewUser(payload)
        .then(() => {
          setData({ ...data, isSuccess: true });
          setTimeout(() => {
            props.history.push("/login");
          }, 3000);

        })
        .catch(() => {

          setData({ ...data, isSuccess: false });

          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.INVALID" },
              { name: values.password }
            )
          );
        });
    },
  });

  //http://localhost:3000/auth/set-password-new-user?email=jenil@primefreight.com&invite_verification=fd005a41-88a6-45a8-93cb-e285099102c3
  return (
    <>
      {
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Reset Password</h3>
            {data.spinner && <Spinner animation="border" variant="primary" />}
            <br>
            </br>

            {data.verificationStatus &&
              <div className="text-muted font-weight-bold">
                Enter your new password
              </div>
            }

            <br>
            </br>
            {!data.spinner && !data.verificationStatus && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  Verification link is wrong
                </div>
              </div>
            )}
            {data.verificationStatus &&

              <form
                onSubmit={resetPassword.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
              >
                {resetPassword.status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">
                      {resetPassword.status}
                    </div>
                  </div>
                )}

                {data.isSuccess && (
                  <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                    <div className="alert-text font-weight-bold">
                      Password Set Successfully
                    </div>
                  </div>
                )}


                <div className="form-group fv-plugins-icon-container">
                  <input
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "password"
                    )}`}
                    name="password"
                    placeholder="Enter new password"
                    {...resetPassword.getFieldProps("password")}
                  />
                  {resetPassword.touched.password && resetPassword.errors.password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{resetPassword.errors.password}</div>
                    </div>
                  ) : null}
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <input
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "confirmpassword"
                    )}`}
                    placeholder="Enter confirm password"
                    name="confirmpassword"
                    {...resetPassword.getFieldProps("confirmpassword")}
                  />
                  {resetPassword.touched.confirmpassword && resetPassword.errors.confirmpassword ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{resetPassword.errors.confirmpassword}</div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "first_name"
                    )}`}
                    name="first_name"
                    placeholder="Enter first name"
                    {...resetPassword.getFieldProps("first_name")}
                  />
                  {resetPassword.touched.first_name && resetPassword.errors.first_name ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{resetPassword.errors.first_name}</div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "last_name"
                    )}`}
                    name="last_name"
                    placeholder="Enter last name"
                    {...resetPassword.getFieldProps("last_name")}
                  />
                  {resetPassword.touched.last_name && resetPassword.errors.last_name ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{resetPassword.errors.last_name}</div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group d-flex flex-wrap flex-center">
                  <button
                    id="kt_login_forgot_submit"
                    type="submit"
                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    disabled={resetPassword.isSubmitting}
                  >
                    Submit
                  </button>
                </div>
              </form>

            }

          </div>
        </div>
      }
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(SetPassword))
