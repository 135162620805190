import axios from "axios";

export const API_URL =
  process.env.NODE_ENV === "development"
    ? "https://primefreight.betty.app/api/runtime/fba8c23dd1104240bfdb9a1b10ef6dbe"
    : "https://primefreight.betty.app/api/runtime/da93364a26fb4eeb9e56351ecec79abb";

const obtainJwtToken = async () => {
  const query = `
      mutation Login($authProfileUuid: String!, $username: String!, $password: String!) {
        login(authProfileUuid: $authProfileUuid, username: $username, password: $password) {
          jwtToken
        refreshToken
        }
      }
    `;

  try {
    const response = await axios.post(
      API_URL,
      {
        query,
        variables: {
          authProfileUuid: "17838b935c5a46eebc885bae212d6d86",
          username: "agents",
          password: "admin@123",
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const token = response?.data?.data?.login?.jwtToken;
    return token;
  } catch (error) {
    console.error("Error obtaining JWT token:", error);
    throw error;
  }
};

//FETCHES THE CHARGE TYPE DATA FOR THE FORM DROPDOWNS.
const getChargeTypes = async () => {
  const jwtToken = await obtainJwtToken();

  let query = `
  {
    allChargeTypes
        (take:200
          where: {
      _and: [
        { chargeType: { eq: "Charge" } },
        { chargeIden: { neq: null } },
        { chargeIden: { neq: "" } },
      ]
    } )
    {results
        {
          description
          chargeIden
          id
        }
        totalCount
       },
     }

  `;

  return axios
    .post(
      API_URL,
      { query },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    )
    .then((result) => {
      if (result.data.data && result.data.data.allChargeTypes) {
        const tableData =
            result.data &&
            result.data.data &&
            result.data.data.allChargeTypes.results
              ? result.data.data.allChargeTypes.results.sort((a, b) =>
                  a.description.localeCompare(b.description)
                )
              : [],
          totalCount = result.data.data.allChargeTypes.totalCount;

        return [200, tableData, totalCount];
      }

      return [403, []];
    });
};

//FETCHES THE AIRPORTS DATA FOR THE FORM DROPDOWNS.
const getAirportData = async () => {
  const jwtToken = await obtainJwtToken();

  let query = `{
        allAirports(take: 200)
        {results
            {
              code
              city
              cityIden
            }
            totalCount
           }
         }`;

  return axios
    .post(
      API_URL,
      { query },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    )
    .then((result) => {
      if (result.data.data && result.data.data.allAirports) {
        const tableData =
            result.data &&
            result.data.data &&
            result.data.data.allAirports.results
              ? result.data.data.allAirports.results.sort((a, b) =>
                  a.code.localeCompare(b.code)
                )
              : [],
          totalCount = result.data.data.allAirports.totalCount;

        return [200, tableData, totalCount];
      }

      return [403, []];
    });
};

//FETCHES THE AIRLINES DATA FOR THE FORM DROPDOWNS.
const getAirlineData = async () => {
  const jwtToken = await obtainJwtToken();

  let query = `{
        allCompany
        (take: 200
          where: {
          _or: [
            { entityType: { eq: "Carrier" } },
            { isCarrier: { eq: true } },
          ]
        } )
        {results
            {
              companyName
              id
            }
            totalCount
           },
         }`;

  return axios
    .post(
      API_URL,
      { query },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    )
    .then((result) => {
      if (result.data.data && result.data.data.allCompany) {
        const tableData =
            result.data &&
            result.data.data &&
            result.data.data.allCompany.results
              ? result.data.data.allCompany.results.sort((a, b) =>
                  a.companyName.localeCompare(b.companyName)
                )
              : [],
          totalCount = result.data.data.allCompany.totalCount;
        return [200, tableData, totalCount];
      }
      return [403, []];
    });
};

//SUBMITS THE DATA TO BB TO CREATE THE RECORDS.
const sendRecords = async (input, charges, submissionNotes, id) => {
  const updatedRecords = input.map((record) => ({
    ...record,
    weight: record.weight.filter((weightObj) => weightObj.value !== ""),
  }));

  const payload = { rates: updatedRecords, submissionNotes: submissionNotes };

  if (charges && charges.length > 0 && charges[0].name != "") {
    payload.charges = charges;
  }

  if (process.env.NODE_ENV === "development") {
    payload.test = true;
  }

  let query = {
    query: "mutation { action(id: $action_id input: $input)}",
    variables: {
      action_id: "4fc984fb4c4e4fb496259d4754f70618",
      input: {
        entity_id: id,
        payload: payload,
      },
    },
  };

  return axios.post(API_URL, query).then((result) => {
    if (result?.data?.data?.errors) {
      console.log("error", result?.data?.data?.errors);
      return true;
    }
    return false;
  });
};

export { sendRecords, getAirportData, getAirlineData, getChargeTypes };
