import React from "react";
import { FormControl } from "react-bootstrap";

const NumericInputControl = ({ value, onChange, width, placeholder }) => (
  <FormControl
    type="text"
    placeholder={placeholder}
    value={value}
    style={{ minWidth: width, textAlign: "center" }}
    onChange={(e) => {
      const numericValue = e.target.value.replace(/[^0-9.,]/g, "");
      onChange(numericValue);
    }}
  />
);

export default NumericInputControl;
