import { useState, useEffect } from "react";

function useFetchWithRetry(
  fetchFunction,
  params = [],
  retries = 3,
  delay = 1000
) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async (retryCount) => {
      try {
        if (!isMounted) return;
        const result = await fetchFunction(...params);
        if (isMounted) setData(result[1]);
      } catch (err) {
        if (retryCount > 0 && isMounted) {
          setTimeout(() => fetchData(retryCount - 1), delay);
        } else if (isMounted) {
          setError(err);
        }
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    fetchData(retries);

    return () => {
      isMounted = false;
    };
  }, [fetchFunction, JSON.stringify(params), retries, delay]);

  return { data, loading, error };
}

export default useFetchWithRetry;
