/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { useEffect } from "react";
import crypto from "crypto";
import AirRatePage from "./modules/AirRate/AirRatePage";
import PoAndStyleManager from "./modules/PoAndStyleManager/PoAndStyleManager";
import IFramePage from "./modules/IFrame/IFramePage";

export function Routes() {
  let location = useLocation();
  const { isAuthorized, userData } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      userData: auth.user,
    }),
    shallowEqual
  );
  useEffect(() => {
    // insert script
    // fetch data from store
    if (isAuthorized && userData) {
      const hmac = crypto.createHmac(
        "sha256",
        "64408e325c0a089293dd5c59edcdf036"
      );
      const userHash = hmac
        .update(userData ? userData.email : "gabriel@primefreight.com")
        .digest("hex");

      // console.log(userHash);
      // console.log(userData.email);
      window.FrontChat("init", {
        chatId: "f672cbdb95f921a1c8ef03fab77b0ff2",
        useDefaultLauncher: false,
        email: userData.email,
        userHash,
      });

      window.hdlh = {
        widget_key: "m6oxveuezkfad0bjx797",
      };
      (function(h, d) {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.defer = true;
        s.src = h + "?t=" + new Date().getTime();
        d.head.appendChild(s);
      })("https://lighthouse.helpdocs.io/load", document);
    }
  }, [isAuthorized]);

  return (
    <Switch>
      {!isAuthorized &&
      location.pathname == "/booking/purchase-order-and-style-manager/" ? (
        <Route
          path="/booking/purchase-order-and-style-manager/"
          component={PoAndStyleManager}
        />
      ) : !isAuthorized &&
        location.pathname == "/shipments/purchase-order-and-style-manager/" ? (
        <Route
          path="/shipments/purchase-order-and-style-manager/"
          component={PoAndStyleManager}
        />
      ) : !isAuthorized && location.pathname == "/air-rate/" ? (
        <Route>
          <Route path="/air-rate/" component={AirRatePage} />
        </Route>
      ) : !isAuthorized && location.pathname.startsWith("/iframe") ? (
        <Route>
          <Route path="/iframe/" component={IFramePage} />
        </Route>
      ) : !isAuthorized ? (
        <Route>
          <AuthPage />
        </Route>
      ) : (
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
