/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { useHistory } from "react-router-dom";
//import { shallowEqual, useSelector } from "react-redux";


export function DocumentationButton() {
  //const user = useSelector((state) => state.auth.user, shallowEqual);
  return (
    // <Dropdown drop="down" alignRight onClick={() => window.open(`https://help.primefreight.com/?user==${encodeURIComponent(JSON.stringify(user))}`, '_blank')}>
    <Dropdown drop="down" alignRight onClick={() => window.open('https://help.primefreight.com/', '_blank')}>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-my-cart-toggle"
      >
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="mycart-panel-tooltip">Go to help</Tooltip>}
        >
          <div className="btn btn-icon btn-clean btn-lg mr-1">
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Question-circle.svg")} />
            </span>
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>

    </Dropdown>
  );
}
