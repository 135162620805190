import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword, checkOtpHTTP, resetPasswordHTTP } from "../_redux/authCrud";
import { values } from "lodash";

const initialValues = {
  email: "",
  otp: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [data, setData] = useState({
    email: "",
    otp: ""
  });

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const OtpSchema = Yup.object().shape({
    otp: Yup.string()
      .min(3, "Minimum 3 character")
      .max(5, "Maximum 5 character")
      .matches(/^[0-9\s]+$/, "OTP must be 5 digit number")
      .required(
        intl.formatMessage(
          { id: "AUTH.VALIDATION.INVALID" },
          { name: values.otp || "OTP" }

        )
      ),
  });

  const ResetpasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(0, "Minimum 0 character")
      .max(50, "Maximum 50 character")
      .required(
        intl.formatMessage(
          { id: "AUTH.VALIDATION.REQUIRED_FIELD" },
        )
      ),
    confirmpassword: Yup.string()
      .min(0, "Minimum 0 character")
      .max(50, "Maximum 50 character")
      .required(
        intl.formatMessage(
          { id: "AUTH.VALIDATION.REQUIRED_FIELD" },
        )
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const getOTPClasses = (fieldname) => {
    if (checkOtp.touched[fieldname] && checkOtp.errors[fieldname]) {
      return "is-invalid";
    }

    if (checkOtp.touched[fieldname] && !checkOtp.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      requestPassword(values.email)
        .then((result) => {

          if (!result) {
            setIsRequested(false);
            setSubmitting(false);
            setStatus(
              intl.formatMessage(
                { id: "AUTH.VALIDATION.NOT_FOUND" },
                { name: values.email }
              )
            );
            return
          }
          setData({
            ...data,
            email: values.email
          });
          setIsRequested(true);
        })
        .catch((error) => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" },
              { name: values.email }
            )
          );

        });
    },
  });

  const checkOtp = useFormik({
    initialValues,
    validationSchema: OtpSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (!data.email) {
        setSubmitting(false);
        setStatus(
          intl.formatMessage(
            { id: "AUTH.VALIDATION.NOT_FOUND" },
            { name: values.email }
          )
        );

        return;
      }

      checkOtpHTTP(data.email, values.otp)
        .then((result) => {

          if (!result) {
            setSubmitting(false);
            setStatus(
              intl.formatMessage(
                { id: "AUTH.VALIDATION.INVALID" },
                { name: values.otp }
              )
            );
            return;
          }
          setData({
            ...data,
            otp: values.otp,
          })
          setIsResetPassword(true);
        })
        .catch(() => {

          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.INVALID" },
              { name: values.otp }
            )
          );
        });
    },
  });

  const resetPassword = useFormik({
    initialValues,
    validationSchema: ResetpasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (values.password !== values.confirmpassword) {
        setSubmitting(false);
        setStatus(
          intl.formatMessage(
            { id: "AUTH.VALIDATION.INVALID" },
            { name: 'Password and Confirm Password must be same' }
          )
        );
        return;
      }
      if (!values.password || !values.confirmpassword || !data.email || !data.otp) {

        setSubmitting(false);

        setStatus(
          intl.formatMessage(
            { id: "AUTH.VALIDATION.INVALID" },
            { name: 'Please Try After Sometime' }
          )
        );
        return;
      }
      let payload = {
        email: data.email,
        otp: data.otp,
        password: values.password
      }

      resetPasswordHTTP(payload)
        .then((result) => {

          if (!result) {
            setSubmitting(false);
            setStatus(
              intl.formatMessage(
                { id: "AUTH.VALIDATION.INVALID" },
                { name: "Failed to update password" }
              )
            );
            return
          }
          setisSuccess(true);
          setTimeout(() => {
            props.history.push("/login");
          }, 2000);

        })
        .catch(() => {
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.INVALID" },
              { name: "Failed to update password" }
            )
          );
        });
    },
  });

  return (
    <>
      {data.otp && isResetPassword &&
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Reset Password</h3>
            <div className="text-muted font-weight-bold">
              Enter your new password
            </div>
          </div>
          <form
            onSubmit={resetPassword.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {resetPassword.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {resetPassword.status}
                </div>
              </div>
            )}

            {isSuccess && (
              <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                <div className="alert-text font-weight-bold">
                  Password Reset Successfully
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                name="password"
                placeholder="Enter new password"
                {...resetPassword.getFieldProps("password")}
              />
              {resetPassword.touched.password && resetPassword.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{resetPassword.errors.password}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "confirmpassword"
                )}`}
                placeholder="Enter confirm password"
                name="confirmpassword"
                {...resetPassword.getFieldProps("confirmpassword")}
              />
              {resetPassword.touched.confirmpassword && resetPassword.errors.confirmpassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{resetPassword.errors.confirmpassword}</div>
                </div>
              ) : null}
            </div>

            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={resetPassword.isSubmitting}
              >
                Submit
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      }
      {isRequested && !isResetPassword &&
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">OTP Has been sent successfully on your email</h3>
            <div className="text-muted font-weight-bold">
              Enter your OTP(One time password) to reset your password
            </div>
          </div>
          <form
            onSubmit={checkOtp.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {checkOtp.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {checkOtp.status}
                </div>
              </div>
            )}

            <div className="form-group fv-plugins-icon-container">
              <input
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getOTPClasses(
                  "otp"
                )}`}
                placeholder="Enter your otp"
                name="otp"
                {...checkOtp.getFieldProps("otp")}
              />
              {checkOtp.touched.otp && checkOtp.errors.otp ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{checkOtp.errors.otp}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={checkOtp.isSubmitting}
              >
                Submit
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Forgotten Password ?</h3>
            <div className="text-muted font-weight-bold">
              Enter your email to reset your password
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                placeholder="Enter your email"
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                Submit
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
