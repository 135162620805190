import React, { useState, useEffect } from "react";

const useInputHandler = (initialState, setRecordsFunction) => {
  const [records, setRecords] = useState(initialState);

  const handleInputChange = (index, fieldName, value) => {
    const updatedRecords = [...initialState];

    updatedRecords[index] = {
      ...updatedRecords[index],
      [fieldName]: value,
    };
    setRecords(updatedRecords);
  };

  const handleNumberValue = (
    recordIndex,
    property,
    newValue,
    nestedIndex = null
  ) => {
    const numericValue = newValue.replace(/[^0-9.,]/g, "");

    const updatedRecords = [...initialState];
    if (nestedIndex !== null) {
      const updatedWeights = [...updatedRecords[recordIndex].weight];
      updatedWeights[nestedIndex] = {
        ...updatedWeights[nestedIndex],
        value: numericValue,
      };
      updatedRecords[recordIndex].weight = updatedWeights;
    } else {
      updatedRecords[recordIndex][property] = numericValue;
    }

    setRecords(updatedRecords);
  };

  const handleDeleteRecord = (index) => {
    const updatedRecords = [...initialState];
    updatedRecords.splice(index, 1);
    setRecords(updatedRecords);
  };

  useEffect(() => {
    setRecordsFunction(records);
  }, [records, setRecordsFunction]);

  return { records, handleInputChange, handleDeleteRecord, handleNumberValue };
};

export default useInputHandler;
